import classNames from 'classnames';
import styles from './ClutchStars.module.scss';

interface IProps {
  filledCount: number,
  starSize?: number,
}

const ClutchStars = ({ filledCount, starSize = 16 }: IProps) => (
  <div className={styles.stars}>
    {new Array(5).fill(0).map((item, index) => (

      <svg // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={classNames(styles.star, {
          [styles.filled]: filledCount > index,
        })}
        width={starSize}
        height={starSize}
      >
        <use
          xlinkHref='/media/star.svg#star'
          href='/media/star.svg#star'
        />
      </svg>
    ))}
  </div>
);

export default ClutchStars;
