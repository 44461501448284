import classNames from 'classnames';
import styles from './ReviewsList.module.scss';

export type PaginationItem = {
  title: string;
  content: string;
};

interface IPaginationButtonProps {
  item: PaginationItem;
  handleChange: () => void;
  isActive: boolean;
}

const PaginationButton = ({ item, handleChange, isActive }: IPaginationButtonProps) => (
  <button
    type='button'
    className={classNames(styles.paginationButton, {
      [styles.active]: isActive,
    })}
    onClick={handleChange}
  >
    <p className={styles.buttonTitle}>{item.title}</p>
    <p className={styles.buttonContent}>{item.content}</p>
    <svg className={styles.activeEllipse}>
      <use
        href='media/ellipses/cases-slider-ellipse.svg#cases-slider-ellipse'
        xlinkHref='media/ellipses/cases-slider-ellipse.svg#cases-slider-ellipse'
      />
    </svg>
  </button>
);

export default PaginationButton;
