import { ReviewList, ReviewListView } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { RefObject, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import styles from './ReviewsList.module.scss';
import PaginationSlider from './PaginationSlider';
import Img from '../../../../components/Img';
import { theme } from '../../../../store/theme';
import FullReview from './FullReview';
import '@splidejs/react-splide/css';

interface IReviewsListProps {
  data: ReviewList;
  view: ReviewListView;
}

const ReviewsList = ({ data, view }: IReviewsListProps) => {
  const { reviews } = data;
  const appTheme = useAtomValue(theme);
  const reviewsSliderRef = useRef<Splide>(null);
  const videoRefs = data.reviews.map(() => useRef<HTMLIFrameElement>(null));
  const [showVideos, setShowVideos] = useState<boolean[]>(Array(data.reviews.length).fill(false));

  function controlVideo(controlFunction: string, ref?: RefObject<HTMLIFrameElement>) {
    if (ref) {
      ref.current?.contentWindow?.postMessage(
        `{"event":"command","func":"${controlFunction}","args":""}`,
        '*',
      );
    } else {
      videoRefs.map(videoRef => videoRef.current?.contentWindow?.postMessage(
        `{"event":"command","func":"${controlFunction}","args":""}`,
        '*',
      ));
    }
  }

  return (
    <div className={classNames(styles.reviewsList, styles[`${view}-view`])}>
      <PaginationSlider
        data={reviews}
        handleChange={(index) => reviewsSliderRef.current?.splide?.go(index)}
      />
      <Splide
        hasTrack={false}
        options={{
          focus: 'center',
          arrows: false,
          type: 'loop',
          perPage: 1,
          pagination: false,
          drag: false,
        }}
        className={styles.slider}
        ref={reviewsSliderRef}
      >
        <SplideTrack className={styles.track}>
          {reviews.map((review, index) => (
            <SplideSlide key={review.case.id}>
              {/* <ReviewsVideo
                className={styles.review}
                review={review}
                ref={videoRefs[index]} // eslint-disable-next-line react/jsx-no-bind
                controlVideo={controlVideo}
                showVideo={showVideos[index]}
                setShowVideo={(value) => {
                  const newShowVideos = [...showVideos];
                  newShowVideos[index] = value;
                  setShowVideos(newShowVideos);
                }}
                view={view}
              /> */}
              <FullReview
                className={styles.review}
                review={review}
                ref={videoRefs[index]} // eslint-disable-next-line react/jsx-no-bind
                controlVideo={controlVideo}
                showVideo={showVideos[index]}
                setShowVideo={(value) => {
                  const newShowVideos = [...showVideos];
                  newShowVideos[index] = value;
                  setShowVideos(newShowVideos);
                }}
                view={view}
              />
            </SplideSlide>
          ))}
        </SplideTrack>
        <div className={classNames(styles.navigation, 'splide__arrows')}>
          <button
            onClick={() => {
              Array(data.reviews.length).fill(false);
              controlVideo('stopVideo');
            }}
            type='button'
            className={classNames('splide__arrow splide__arrow--prev', styles.arrow, styles.arrow_prev)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
          <button
            onClick={() => {
              setShowVideos(Array(data.reviews.length).fill(false));
              controlVideo('stopVideo');
            }}
            type='button'
            className={classNames('splide__arrow splide__arrow--next', styles.arrow, styles.arrow_next)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
        </div>
      </Splide>
    </div>
  );
};

export default ReviewsList;
