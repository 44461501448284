import { IReviewQuote, ViewValues } from '@sapientpro/sapientpro-data-models';
import {
  Options, SplideSlide,
} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useAtomValue } from 'jotai';
import React from 'react';
import { MouseParallax } from 'react-just-parallax';
import classNames from 'classnames';
import Ellipse, { EllipseVariants } from '../../../../components/Ellipse';
import Slider from '../../../../components/Slider';
import deviceScreenSize, { ScreenSize } from '../../../../store/deviceScreenSize';
import ReviewsTextSlide from './ReviewsTextSlide';
import styles from './ReviewsTextSlider.module.scss';

interface IReviewsTextSliderProps {
  data: IReviewQuote[];
  view: ViewValues;
}

const defaultSettings = {
  focus: 0,
  perPage: 1,
  perMove: 1,
  pagination: true,
  arrows: false,
  type: 'loop',
  classes: {
    pagination: `splide__pagination ${styles.pagination}`,
    page: `splide__pagination__page ${styles.pagination__item}`,
  },
};

const uiuxSettings = {
  focus: 0,
  pagination: false,
  arrows: false,
  autoWidth: true,
  gap: 16,
  classes: {
    pagination: `splide__pagination ${styles.pagination}`,
    page: `splide__pagination__page ${styles.pagination__item}`,
  },
  breakpoints: {
    1023: {
      padding: { left: 64 },
    },
    767: {
      padding: { left: 24 },
    },
  },
};

const getSliderSettings = (view: ViewValues): Options => {
  switch (view) {
    case ('uiux-page'): {
      return uiuxSettings;
    }
    default: return defaultSettings;
  }
};

const ReviewsTextSlider = ({ data, view }: IReviewsTextSliderProps) => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const showParallax = view !== 'uiux-page';

  return (
    <div className={classNames(styles.reviews, styles[`${view}-view`])}>
      {showParallax && (
      <>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.05}
        >
          <Ellipse
            variant={EllipseVariants.DRAW}
            size={deviceSize <= ScreenSize.TABLET_PORTRAIT ? 516 : 720}
            className={styles.parallax__draw}
          />
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.12}
        >
          <Ellipse
            variant={EllipseVariants.NOISE}
            size={deviceSize <= ScreenSize.TABLET_PORTRAIT ? 84 : 117}
            className={styles.parallax__noise}
          />
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.08}
        >
          <Ellipse
            variant={EllipseVariants.BORDER}
            size={deviceSize <= ScreenSize.TABLET_PORTRAIT ? 28 : 40}
            className={styles.parallax__border}
          />
        </MouseParallax>
      </>
      )}

      {data && (
        <Slider
          settings={getSliderSettings(view)}
          classNames={{
            slider: styles.slider,
            track: styles.track,
            navigation: styles.navigation,
            arrow: styles.arrow,
            prevArrow: styles.arrow_prev,
            nextArrow: styles.arrow_next,
          }}
        >
            {data.map(review => (
              <SplideSlide key={review.id}>
                <ReviewsTextSlide
                  className={styles.slide}
                  review={review}
                  view={view}
                />
              </SplideSlide>
            ))}
        </Slider>
      )}
    </div>
  );
};
export default ReviewsTextSlider;
