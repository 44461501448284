import { Options, Splide, SplideTrack } from '@splidejs/react-splide';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { forwardRef } from 'react';
import { theme } from '../store/theme';
import Img from './Img';

type SliderProps = {
  settings: Options,
  classNames: {
    [x: string]: string,
  },
  children: React.ReactNode,
  onMove?: (slideIndex: number) => void;
};

const Slider = forwardRef<Splide, SliderProps>(({
  settings, classNames, children, onMove,
}, ref) => {
  const appTheme = useAtomValue(theme);

  return (
    <Splide
      hasTrack={false}
      options={settings}
      className={classNames.slider}
      onMove={e => onMove?.(e.index)}
      ref={ref}
    >
      <SplideTrack
        className={classNames.track}
      >
        {children}
      </SplideTrack>
      {settings.arrows && (
        <div className={classnames(classNames.navigation, 'splide__arrows')}>
          <button
            type='button'
            className={classnames('splide__arrow splide__arrow--prev', classNames.arrow, classNames.prevArrow)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
          <button
            type='button'
            className={classnames('splide__arrow splide__arrow--next', classNames.arrow, classNames.nextArrow)}
          >
            <Img
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt='slider arrow right'
            />
          </button>
        </div>
      )}
    </Splide>
  );
});

export default Slider;
