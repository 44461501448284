'use client';

import { IReviewComponent, ReviewComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import AnimatedTitle from '../../../components/AnimatedTitle';
import ClutchReviews from './ClutchReviews';
import styles from './Reviews.module.scss';
import ReviewsList from './ReviewsList/ReviewsList';
import ReviewsTextSlider from './ReviewsTextSlider';
import ReviewsVideos from './ReviewsVideos';

const Reviews = ({ data }: { data: ReviewComponent | IReviewComponent }) => (
  <section className={classnames(styles.reviews, styles[`${data.view}-view`])}>
    <header className={classnames(styles.reviews__header, 'container')}>
      <h2 className={classnames('sectionTitle', styles.title)}>
        <AnimatedTitle title={data?.title} />
      </h2>
      {!!data?.description && (
      <p className={classnames(styles.text, styles[`${data.view}-view`])}>
        {data?.description}
      </p>
      )}
    </header>
    <div className={classnames(styles.reviews__content, styles[`${data.view}-view`])}>
      {(data as ReviewComponent).review_list ? (
        <ReviewsList
          data={(data as ReviewComponent).review_list}
          view={(data as ReviewComponent).view}
        />
      ) : (
        <>
          {(data as IReviewComponent).showVideoReviews && (
          <ReviewsVideos
            data={(data as IReviewComponent)?.videoReviews}
            // view={(data as IReviewComponent).view}
          />
          )}
          {(data as IReviewComponent).showTextReviews && (
          <ReviewsTextSlider
            data={(data as IReviewComponent)?.textReviews}
            view={(data as IReviewComponent).view}
          />
          )}
          {(data as IReviewComponent).showClutch && data?.clutch && (
          <ClutchReviews
            data={data.clutch}
            view={(data as IReviewComponent).view}
          />
          )}
        </>
      )}
    </div>
  </section>
);

export default Reviews;
