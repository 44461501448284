import { IClutch, ViewValues } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import Img from '../../../../components/Img';
import { theme } from '../../../../store/theme';

import styles from './ClutchReviews.module.scss';
import ClutchStars from '../../../../components/ClutchStars/ClutchStars';
import deviceScreenSize, { ScreenSize } from '../../../../store/deviceScreenSize';

interface IClutchReviewsProps {
  data: IClutch;
  view: ViewValues;
}

const ClutchReviews = ({ data, view }: IClutchReviewsProps) => {
  const appTheme = useAtomValue(theme);
  const screenSize = useAtomValue(deviceScreenSize);

  const starsSize = screenSize < ScreenSize.TABLET_LANDSCAPE ? 13 : 16;

  return (
    <div className={classNames(styles.review, styles[`${view}-view`])}>
      <img
        src={`/media/clutch_bg_${appTheme}.svg`}
        alt='clutch'
        className={classNames(styles.clutchBg, styles[`${view}-view`])}
      />
      <Img
        src={`/media/clutch_${appTheme}.svg`}
        alt='clutch'
        width={172}
        height={49}
        className={styles.logo}
      />
      <img
        src={`/media/based_bg_${appTheme}.svg`}
        alt='based background'
        className={classNames(styles.basedBg, styles[`${view}-view`])}
      />
      <div className={styles.content}>
        <ClutchStars
          filledCount={5}
          starSize={starsSize}
        />
        <p className={styles.rating}>
          {data.stars}
          {' '}
          out of 5, based on
          {' '}
          <a
            href={data.link}
            className={styles.rating__link}
          >
            {data.numberOfReviews}
            {' '}
            reviews
          </a>
        </p>
        <p className={classNames(styles.text, styles[`${view}-view`])}>
          {data.quote}
        </p>
      </div>
    </div>
  );
};

export default ClutchReviews;
